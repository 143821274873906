import {gql} from "@apollo/client"

export const GET_COMPANIES= gql`
query Companies {
    companies {
      _id
      name
      workingStatus
      vergi {
        vergiDairesi
        vergiNumarasi
      }
      adress
    }
}
`
