import { ApolloClient, ApolloLink, InMemoryCache, from, concat } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from 'apollo-upload-client';

const errorLink = onError(({ graphqlErrors }) => {
  if (graphqlErrors) {
    graphqlErrors.forEach(({ message, locations, path }) => {
      alert(`GraphQL error: ${message}`);
    });
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem('token') || null,
    }
  }));
  return forward(operation);
});

const uploadLink = createUploadLink({ uri: "/graphql" });

const link = from([errorLink, uploadLink]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, link),
  defaultOptions: {
    fetchPolicy: 'no-cache',
  }
});

export default client;
