
const iller=[
    {ilId:1, Sehir : "ADANA" },{ ilId : 2, Sehir : "ADIYAMAN" },{ ilId : 3, Sehir : "AFYON" },{ ilId : 4, Sehir : "AĞRI" },{ ilId : 5, Sehir : "AMASYA" },{ ilId : 6, Sehir : "ANKARA" },
    { ilId : 7, Sehir : "ANTALYA" },{ ilId : 8, Sehir : "ARTVİN" },{ ilId : 9, Sehir : "AYDIN" },{ ilId : 10, Sehir : "BALIKESİR" },{ ilId : 11, Sehir : "BİLECİK" },{ ilId : 12, Sehir : "BİNGÖL" },
    { ilId : 13, Sehir : "BİTLİS" },{ ilId : 14, Sehir : "BOLU" },{ ilId : 15, Sehir : "BURDUR" },{ ilId : 16, Sehir : "BURSA" },{ ilId : 17, Sehir : "ÇANAKKALE" },{ ilId : 18, Sehir : "ÇANKIRI" },
    { ilId : 19, Sehir : "ÇORUM" },{ ilId : 20, Sehir : "DENİZLİ" },{ ilId : 21, Sehir : "DİYARBAKIR" },{ ilId : 22, Sehir : "EDİRNE" },{ ilId : 23, Sehir : "ELAZIĞ" },{ ilId : 24, Sehir : "ERZİNCAN" },
    { ilId : 25, Sehir : "ERZURUM" },{ ilId : 26, Sehir : "ESKİŞEHİR" },{ ilId : 27, Sehir : "GAZİANTEP" },{ ilId : 28, Sehir : "GİRESUN" },{ ilId : 29, Sehir : "GÜMÜŞHANE" },{ ilId : 30, Sehir : "HAKKARİ" },
    { ilId : 31, Sehir : "HATAY" },{ ilId : 32, Sehir : "ISPARTA" },{ ilId : 33, Sehir : "İÇEL" },{ ilId : 34, Sehir : "İSTANBUL" },{ ilId : 35, Sehir : "İZMİR" },{ ilId : 36, Sehir : "KARS" },
    { ilId : 37, Sehir : "KASTAMONU" },{ ilId : 38, Sehir : "KAYSERİ" },{ ilId : 39, Sehir : "KIRKLARELİ" },{ ilId : 40, Sehir : "KIRŞEHİR" },{ ilId : 41, Sehir : "KOCAELİ" },{ ilId : 42, Sehir : "KONYA" },
    { ilId : 43, Sehir : "KÜTAHYA" },{ ilId : 44, Sehir : "MALATYA" },{ ilId : 45, Sehir : "MANİSA" },{ ilId : 46, Sehir : "KAHRAMANMARAŞ" },{ ilId : 47, Sehir : "MARDİN" },{ ilId : 48, Sehir : "MUĞLA" },
    { ilId : 49, Sehir : "MUŞ" },{ ilId : 50, Sehir : "NEVŞEHİR" },{ ilId : 51, Sehir : "NİĞDE" },{ ilId : 52, Sehir : "ORDU" },{ ilId : 53, Sehir : "RİZE" },{ ilId : 54, Sehir : "SAKARYA" },
    { ilId : 55, Sehir : "SAMSUN" },{ ilId : 56, Sehir : "SİİRT" },{ ilId : 57, Sehir : "SİNOP" },{ ilId : 58, Sehir : "SİVAS" },{ ilId : 59, Sehir : "TEKİRDAĞ" },{ ilId : 60, Sehir : "TOKAT" },
    { ilId : 61, Sehir : "TRABZON" },{ ilId : 62, Sehir : "TUNCELİ" },{ ilId : 63, Sehir : "ŞANLIURFA" },{ ilId : 64, Sehir : "UŞAK" },{ ilId : 65, Sehir : "VAN" },{ ilId : 66, Sehir : "YOZGAT" },
    { ilId : 67, Sehir : "ZONGULDAK" },{ ilId : 68, Sehir : "AKSARAY" },{ ilId : 69, Sehir : "BAYBURT" },{ ilId : 70, Sehir : "KARAMAN" },{ ilId : 71, Sehir : "KIRIKKALE" },{ ilId : 72, Sehir : "BATMAN" },
    { ilId : 73, Sehir : "ŞIRNAK" },{ ilId : 74, Sehir : "BARTIN" },{ ilId : 75, Sehir : "ARDAHAN" },{ ilId : 76, Sehir : "IĞDIR" },{ ilId : 77, Sehir : "YALOVA" },{ ilId : 78, Sehir : "KARABÜK" },
    { ilId : 79, Sehir : "KİLİS" },{ ilId : 80, Sehir : "OSMANİYE" },{ ilId : 81, Sehir : "DÜZCE" }
]

const ilceler=[
     {ilceId:1,ilId:1,ilce:"SEYHAN"},{ ilceId : 2, ilId : 1, ilce : "CEYHAN" }, { ilceId : 3, ilId : 1, ilce : "FEKE" },{ ilceId : 4, ilId : 1, ilce : "KARAİSALI" },
     { ilceId : 5, ilId : 1, ilce : "KARATAŞ" }, { ilceId : 6, ilId : 1, ilce : "KOZAN" }, { ilceId : 7, ilId : 1, ilce : "POZANTI" }, { ilceId : 8, ilId : 1, ilce : "SAİMBEYLİ" },
     { ilceId : 9, ilId : 1, ilce : "TUFANBEYLİ" }, { ilceId : 10, ilId : 1, ilce : "YUMURTALIK" }, { ilceId : 11, ilId : 1, ilce : "YÜREĞİR" }, { ilceId : 12, ilId : 1, ilce : "ALADAĞ" }, { ilceId : 13, ilId : 1, ilce : "İMAMOĞLU" }, { ilceId : 14, ilId : 2, ilce : "ADIYAMAN MERKEZ" }, { ilceId : 15, ilId : 2, ilce : "BESNİ" }, { ilceId : 16, ilId : 2, ilce : "ÇELİKHAN" }, { ilceId : 17, ilId : 2, ilce : "GERGER" }, { ilceId : 18, ilId : 2, ilce : "GÖLBAŞI" }, { ilceId : 19, ilId : 2, ilce : "KAHTA" }, 
     { ilceId : 20, ilId : 2, ilce : "SAMSAT" }, { ilceId : 21, ilId : 2, ilce : "SİNCİK" }, { ilceId : 22, ilId : 2, ilce : "TUT" }, { ilceId : 23, ilId : 3, ilce : "AFYONMERKEZ" }, { ilceId : 24, ilId : 3, ilce : "BOLVADİN" }, { ilceId : 25, ilId : 3, ilce : "ÇAY" }, { ilceId : 26, ilId : 3, ilce : "DAZKIRI" }, { ilceId : 27, ilId : 3, ilce : "DİNAR" }, { ilceId : 28, ilId : 3, ilce : "EMİRDAĞ" }, { ilceId : 29, ilId : 3, ilce : "İHSANİYE" }, { ilceId : 30, ilId : 3, ilce : "SANDIKLI" }, { ilceId : 31, ilId : 3, ilce : "SİNANPAŞA" }, { ilceId : 32, ilId : 3, ilce : "SULDANDAĞI" }, { ilceId : 33, ilId : 3, ilce : "ŞUHUT" }, { ilceId : 34, ilId : 3, ilce : "BAŞMAKÇI" },      
     { ilceId : 35, ilId : 3, ilce : "BAYAT" }, { ilceId : 36, ilId : 3, ilce : "İŞCEHİSAR" }, { ilceId : 37, ilId : 3, ilce : "ÇOBANLAR" }, { ilceId : 38, ilId : 3, ilce : "EVCİLER" }, { ilceId : 39, ilId : 3, ilce : "HOCALAR" }, { ilceId : 40, ilId : 3, ilce : "KIZILÖREN" }, { ilceId : 41, ilId : 68, ilce : "AKSARAY MERKEZ" }, { ilceId : 42, ilId : 68, ilce : "ORTAKÖY" }, { ilceId : 43, ilId : 68, ilce : "AĞAÇÖREN" }, { ilceId : 44, ilId : 68, ilce : "GÜZELYURT" }, { ilceId : 45, ilId : 68, ilce : "SARIYAHŞİ" }, { ilceId : 46, ilId : 68, ilce : "ESKİL" }, { ilceId : 47, ilId : 68, ilce : "GÜLAĞAÇ" }, { ilceId : 48, ilId : 5, ilce : "AMASYA MERKEZ" }, 
     { ilceId : 49, ilId : 5, ilce : "GÖYNÜÇEK" }, { ilceId : 50, ilId : 5, ilce : "GÜMÜŞHACIKÖYÜ" }, { ilceId : 51, ilId : 5, ilce : "MERZİFON" }, { ilceId : 52, ilId : 5, ilce : "SULUOVA" }, { ilceId : 53, ilId : 5, ilce : "TAŞOVA" }, { ilceId : 54, ilId : 5, ilce : "HAMAMÖZÜ" }, { ilceId : 55, ilId : 6, ilce : "ALTINDAĞ" }, { ilceId : 56, ilId : 6, ilce : "AYAS" }, { ilceId : 57, ilId : 6, ilce : "BALA" }, { ilceId : 58, ilId : 6, ilce : "BEYPAZARI" }, { ilceId : 59, ilId : 6, ilce : "ÇAMLIDERE" }, { ilceId : 60, ilId : 6, ilce : "ÇANKAYA" }, { ilceId : 61, ilId : 6, ilce : "ÇUBUK" }, { ilceId : 62, ilId : 6, ilce : "ELMADAĞ" }, { ilceId : 63, ilId : 6, ilce : "GÜDÜL" },
     { ilceId : 64, ilId : 6, ilce : "HAYMANA" }, { ilceId : 65, ilId : 6, ilce : "KALECİK" }, { ilceId : 66, ilId : 6, ilce : "KIZILCAHAMAM" }, { ilceId : 67, ilId : 6, ilce : "NALLIHAN" }, { ilceId : 68, ilId : 6, ilce : "POLATLI" }, { ilceId : 69, ilId : 6, ilce : "ŞEREFLİKOÇHİSAR" }, { ilceId : 70, ilId : 6, ilce : "YENİMAHALLE" }, { ilceId : 71, ilId : 6, ilce : "GÖLBAŞI" }, { ilceId : 72, ilId : 6, ilce : "KEÇİÖREN" }, { ilceId : 73, ilId : 6, ilce : "MAMAK" }, { ilceId : 74, ilId : 6, ilce : "SİNCAN" }, { ilceId : 75, ilId : 6, ilce : "KAZAN" }, { ilceId : 76, ilId : 6, ilce : "AKYURT" }, { ilceId : 77, ilId : 6, ilce : "ETİMESGUT" }, { ilceId : 78, ilId : 6, ilce : "EVREN" },
     { ilceId : 79, ilId : 7, ilce : "ANSEKİ" }, { ilceId : 80, ilId : 7, ilce : "ALANYA" }, { ilceId : 81, ilId : 7, ilce : "ANTALYA MERKEZİ" }, { ilceId : 82, ilId : 7, ilce : "ELMALI" }, { ilceId : 83, ilId : 7, ilce : "FİNİKE" }, { ilceId : 84, ilId : 7, ilce : "GAZİPAŞA" }, { ilceId : 85, ilId : 7, ilce : "GÜNDOĞMUŞ" }, { ilceId : 86, ilId : 7, ilce : "KAŞ" }, { ilceId : 87, ilId : 7, ilce : "KORKUTELİ" }, { ilceId : 88, ilId : 7, ilce : "KUMLUCA" }, { ilceId : 89, ilId : 7, ilce : "MANAVGAT" }, { ilceId : 90, ilId : 7, ilce : "SERİK" }, { ilceId : 91, ilId : 7, ilce : "DEMRE" }, { ilceId : 92, ilId : 7, ilce : "İBRADI" }, { ilceId : 93, ilId : 7, ilce : "KEMER" }, { ilceId : 94, ilId : 75, ilce : "ARDAHAN MERKEZ" },
     { ilceId : 95, ilId : 75, ilce : "GÖLE" }, { ilceId : 96, ilId : 75, ilce : "ÇILDIR" }, { ilceId : 97, ilId : 75, ilce : "HANAK" }, { ilceId : 98, ilId : 75, ilce : "POSOF" }, { ilceId : 99, ilId : 75, ilce : "DAMAL" }, { ilceId : 100, ilId : 8, ilce : "ARDANUÇ" }, { ilceId : 101, ilId : 8, ilce : "ARHAVİ" }, { ilceId : 102, ilId : 8, ilce : "ARTVİN MERKEZ" }, { ilceId : 103, ilId : 8, ilce : "BORÇKA" }, { ilceId : 104, ilId : 8, ilce : "HOPA" }, { ilceId : 105, ilId : 8, ilce : "ŞAVŞAT" }, { ilceId : 106, ilId : 8, ilce : "YUSUFELİ" }, { ilceId : 107, ilId : 8, ilce : "MURGUL" }, { ilceId : 108, ilId : 9, ilce : "AYDIN MERKEZ" }, { ilceId : 109, ilId : 9, ilce : "BOZDOĞAN" }, { ilceId : 110, ilId : 9, ilce : "ÇİNE" },
     { ilceId : 111, ilId : 9, ilce : "GERMENCİK" }, { ilceId : 112, ilId : 9, ilce : "KARACASU" }, { ilceId : 113, ilId : 9, ilce : "KOÇARLI" }, { ilceId : 114, ilId : 9, ilce : "KUŞADASI" }, { ilceId : 115, ilId : 9, ilce : "KUYUCAK" }, { ilceId : 116, ilId : 9, ilce : "NAZİLLİ" }, { ilceId : 117, ilId : 9, ilce : "SÖKE" }, { ilceId : 118, ilId : 9, ilce : "SULTANHİSAR" }, { ilceId : 119, ilId : 9, ilce : "YENİPAZAR" }, { ilceId : 120, ilId : 9, ilce : "BUHARKENT" }, { ilceId : 121, ilId : 9, ilce : "İNCİRLİOVA" }, { ilceId : 122, ilId : 9, ilce : "KARPUZLU" }, { ilceId : 123, ilId : 9, ilce : "KÖŞK" }, { ilceId : 124, ilId : 9, ilce : "DİDİM" }, { ilceId : 125, ilId : 4, ilce : "AĞRI MERKEZ" }, { ilceId : 126, ilId : 4, ilce : "DİYADİN" },
     { ilceId : 127, ilId : 4, ilce : "DOĞUBEYAZIT" }, { ilceId : 128, ilId : 4, ilce : "ELEŞKİRT" }, { ilceId : 129, ilId : 4, ilce : "HAMUR" }, { ilceId : 130, ilId : 4, ilce : "PATNOS" }, { ilceId : 131, ilId : 4, ilce : "TAŞLIÇAY" }, { ilceId : 132, ilId : 4, ilce : "TUTAK" }, { ilceId : 133, ilId : 10, ilce : "AYVALIK" }, { ilceId : 134, ilId : 10, ilce : "BALIKESİR MERKEZ" }, { ilceId : 135, ilId : 10, ilce : "BALYA" }, { ilceId : 136, ilId : 10, ilce : "BANDIRMA" }, { ilceId : 137, ilId : 10, ilce : "BİGADİÇ" }, { ilceId : 138, ilId : 10, ilce : "BURHANİYE" }, { ilceId : 139, ilId : 10, ilce : "DURSUNBEY" }, { ilceId : 140, ilId : 10, ilce : "EDREMİT" }, { ilceId : 141, ilId : 10, ilce : "ERDEK" }, { ilceId : 142, ilId : 10, ilce : "GÖNEN" },
     { ilceId : 143, ilId : 10, ilce : "HAVRAN" }, { ilceId : 144, ilId : 10, ilce : "İVRİNDİ" }, { ilceId : 145, ilId : 10, ilce : "KEPSUT" }, { ilceId : 146, ilId : 10, ilce : "MANYAS" }, { ilceId : 147, ilId : 10, ilce : "SAVAŞTEPE" }, { ilceId : 148, ilId : 10, ilce : "SINDIRGI" }, { ilceId : 149, ilId : 10, ilce : "SUSURLUK" }, { ilceId : 150, ilId : 10, ilce : "MARMARA" }, { ilceId : 151, ilId : 10, ilce : "GÖMEÇ" }, { ilceId : 152, ilId : 74, ilce : "BARTIN MERKEZ" }, { ilceId : 153, ilId : 74, ilce : "KURUCAŞİLE" }, { ilceId : 154, ilId : 74, ilce : "ULUS" }, { ilceId : 155, ilId : 74, ilce : "AMASRA" }, { ilceId : 156, ilId : 72, ilce : "BATMAN MERKEZ" }, { ilceId : 157, ilId : 72, ilce : "BEŞİRİ" }, { ilceId : 158, ilId : 72, ilce : "GERCÜŞ" },
     { ilceId : 159, ilId : 72, ilce : "KOZLUK" }, { ilceId : 160, ilId : 72, ilce : "SASON" }, { ilceId : 161, ilId : 72, ilce : "HASANKEYF" }, { ilceId : 162, ilId : 69, ilce : "BAYBURT MERKEZ" }, { ilceId : 163, ilId : 69, ilce : "AYDINTEPE" }, { ilceId : 164, ilId : 69, ilce : "DEMİRÖZÜ" }, { ilceId : 165, ilId : 14, ilce : "BOLU MERKEZ" }, { ilceId : 166, ilId : 14, ilce : "GEREDE" }, { ilceId : 167, ilId : 14, ilce : "GÖYNÜK" }, { ilceId : 168, ilId : 14, ilce : "KIBRISCIK" }, { ilceId : 169, ilId : 14, ilce : "MENGEN" }, { ilceId : 170, ilId : 14, ilce : "MUDURNU" }, { ilceId : 171, ilId : 14, ilce : "SEBEN" }, { ilceId : 172, ilId : 14, ilce : "DÖRTDİVAN" }, { ilceId : 173, ilId : 14, ilce : "YENİÇAĞA" }, { ilceId : 174, ilId : 15, ilce : "AĞLASUN" },
     { ilceId : 175, ilId : 15, ilce : "BUCAK" }, { ilceId : 176, ilId : 15, ilce : "BURDUR MERKEZ" }, { ilceId : 177, ilId : 15, ilce : "GÖLHİSAR" }, { ilceId : 178, ilId : 15, ilce : "TEFENNİ" }, { ilceId : 179, ilId : 15, ilce : "YEŞİLOVA" }, { ilceId : 180, ilId : 15, ilce : "KARAMANLI" }, { ilceId : 181, ilId : 15, ilce : "KEMER" }, { ilceId : 182, ilId : 15, ilce : "ALTINYAYLA" }, { ilceId : 183, ilId : 15, ilce : "ÇAVDIR" }, { ilceId : 184, ilId : 15, ilce : "ÇELTİKÇİ" }, { ilceId : 185, ilId : 16, ilce : "GEMLİK" }, { ilceId : 186, ilId : 16, ilce : "İNEGÖL" }, { ilceId : 187, ilId : 16, ilce : "İZNİK" }, { ilceId : 188, ilId : 16, ilce : "KARACABEY" }, { ilceId : 189, ilId : 16, ilce : "KELES" }, { ilceId : 190, ilId : 16, ilce : "MUDANYA" }, { ilceId : 191, ilId : 16, ilce : "MUSTAFA K. PAŞA" },
     { ilceId : 192, ilId : 16, ilce : "ORHANELİ" }, { ilceId : 193, ilId : 16, ilce : "ORHANGAZİ" }, { ilceId : 194, ilId : 16, ilce : "YENİŞEHİR" }, { ilceId : 195, ilId : 16, ilce : "BÜYÜK ORHAN" }, { ilceId : 196, ilId : 16, ilce : "HARMANCIK" }, { ilceId : 197, ilId : 16, ilce : "NÜLİFER" }, { ilceId : 198, ilId : 16, ilce : "OSMAN GAZİ" }, { ilceId : 199, ilId : 16, ilce : "YILDIRIM" }, { ilceId : 200, ilId : 16, ilce : "GÜRSU" }, { ilceId : 201, ilId : 16, ilce : "KESTEL" }, { ilceId : 202, ilId : 11, ilce : "BİLECİK MERKEZ" }, { ilceId : 203, ilId : 11, ilce : "BOZÜYÜK" }, { ilceId : 204, ilId : 11, ilce : "GÖLPAZARI" }, { ilceId : 205, ilId : 11, ilce : "OSMANELİ" }, { ilceId : 206, ilId : 11, ilce : "PAZARYERİ" }, { ilceId : 207, ilId : 11, ilce : "SÖĞÜT" }, 
     { ilceId : 208, ilId : 11, ilce : "YENİPAZAR" }, { ilceId : 209, ilId : 11, ilce : "İNHİSAR" }, { ilceId : 210, ilId : 12, ilce : "BİNGÖL MERKEZ" }, { ilceId : 211, ilId : 12, ilce : "GENÇ" }, { ilceId : 212, ilId : 12, ilce : "KARLIOVA" }, { ilceId : 213, ilId : 12, ilce : "KİGI" }, { ilceId : 214, ilId : 12, ilce : "SOLHAN" }, { ilceId : 215, ilId : 12, ilce : "ADAKLI" }, { ilceId : 216, ilId : 12, ilce : "YAYLADERE" }, { ilceId : 217, ilId : 12, ilce : "YEDİSU" },{ ilceId : 218, ilId : 13, ilce : "ADİLCEVAZ" }, { ilceId : 219, ilId : 13, ilce : "AHLAT" }, { ilceId : 220, ilId : 13, ilce : "BİTLİS MERKEZ" }, { ilceId : 221, ilId : 13, ilce : "HİZAN" }, { ilceId : 222, ilId : 13, ilce : "MUTKİ" }, { ilceId : 223, ilId : 13, ilce : "TATVAN" }, { ilceId : 224, ilId : 13, ilce : "GÜROYMAK" },
     { ilceId : 225, ilId : 20, ilce : "DENİZLİ MERKEZ" }, { ilceId : 226, ilId : 20, ilce : "ACIPAYAM" }, { ilceId : 227, ilId : 20, ilce : "BULDAN" }, { ilceId : 228, ilId : 20, ilce : "ÇAL" }, { ilceId : 229, ilId : 20, ilce : "ÇAMELİ" }, { ilceId : 230, ilId : 20, ilce : "ÇARDAK" }, { ilceId : 231, ilId : 20, ilce : "ÇİVRİL" }, { ilceId : 232, ilId : 20, ilce : "GÜNEY" }, { ilceId : 233, ilId : 20, ilce : "KALE" }, { ilceId : 234, ilId : 20, ilce : "SARAYKÖY" }, { ilceId : 235, ilId : 20, ilce : "TAVAS" }, { ilceId : 236, ilId : 20, ilce : "BABADAĞ" }, { ilceId : 237, ilId : 20, ilce : "BEKİLLİ" }, { ilceId : 238, ilId : 20, ilce : "HONAZ" }, { ilceId : 239, ilId : 20, ilce : "SERİNHİSAR" }, { ilceId : 240, ilId : 20, ilce : "AKKÖY" }, { ilceId : 241, ilId : 20, ilce : "BAKLAN" }, { ilceId : 242, ilId : 20, ilce : "BEYAĞAÇ" },
     { ilceId : 243, ilId : 20, ilce : "BOZKURT" }, { ilceId : 244, ilId : 81, ilce : "DÜZCE MERKEZ" }, { ilceId : 245, ilId : 81, ilce : "AKÇAKOCA" }, { ilceId : 246, ilId : 81, ilce : "YIĞILCA" }, { ilceId : 247, ilId : 81, ilce : "CUMAYERİ" }, { ilceId : 248, ilId : 81, ilce : "GÖLYAKA" }, { ilceId : 249, ilId : 81, ilce : "ÇİLİMLİ" }, { ilceId : 250, ilId : 81, ilce : "GÜMÜŞOVA" }, { ilceId : 251, ilId : 81, ilce : "KAYNAŞLI" }, { ilceId : 252, ilId : 21, ilce : "DİYARBAKIR MERKEZ" }, { ilceId : 253, ilId : 21, ilce : "BİSMİL" }, { ilceId : 254, ilId : 21, ilce : "ÇERMİK" }, { ilceId : 255, ilId : 21, ilce : "ÇINAR" }, { ilceId : 256, ilId : 21, ilce : "ÇÜNGÜŞ" }, { ilceId : 257, ilId : 21, ilce : "DİCLE" }, { ilceId : 258, ilId : 21, ilce : "ERGANİ" }, { ilceId : 259, ilId : 21, ilce : "HANİ" }, { ilceId : 260, ilId : 21, ilce : "HAZRO" },
     { ilceId : 261, ilId : 21, ilce : "KULP" }, { ilceId : 262, ilId : 21, ilce : "LİCE" }, { ilceId : 263, ilId : 21, ilce : "SİLVAN" }, { ilceId : 264, ilId : 21, ilce : "EĞİL" }, { ilceId : 265, ilId : 21, ilce : "KOCAKÖY" }, { ilceId : 266, ilId : 22, ilce : "EDİRNE MERKEZ" }, { ilceId : 267, ilId : 22, ilce : "ENEZ" }, { ilceId : 268, ilId : 22, ilce : "HAVSA" }, { ilceId : 269, ilId : 22, ilce : "İPSALA" }, { ilceId : 270, ilId : 22, ilce : "KEŞAN" }, { ilceId : 271, ilId : 22, ilce : "LALAPAŞA" }, { ilceId : 272, ilId : 22, ilce : "MERİÇ" }, { ilceId : 273, ilId : 22, ilce : "UZUNKÖPRÜ" }, { ilceId : 274, ilId : 22, ilce : "SÜLOĞLU" }, { ilceId : 275, ilId : 23, ilce : "ELAZIĞ MERKEZ" }, { ilceId : 276, ilId : 23, ilce : "AĞIN" }, { ilceId : 277, ilId : 23, ilce : "BASKİL" }, { ilceId : 278, ilId : 23, ilce : "KARAKOÇAN" },
     { ilceId : 279, ilId : 23, ilce : "KEBAN" }, { ilceId : 280, ilId : 23, ilce : "MADEN" }, { ilceId : 281, ilId : 23, ilce : "PALU" }, { ilceId : 282, ilId : 23, ilce : "SİVRİCE" }, { ilceId : 283, ilId : 23, ilce : "ARICAK" }, { ilceId : 284, ilId : 23, ilce : "KOVANCILAR" }, { ilceId : 285, ilId : 23, ilce : "ALACAKAYA" }, { ilceId : 286, ilId : 25, ilce : "ERZURUM MERKEZ" }, { ilceId : 287, ilId : 25, ilce : "PALANDÖKEN" }, { ilceId : 288, ilId : 25, ilce : "AŞKALE" }, { ilceId : 289, ilId : 25, ilce : "ÇAT" }, { ilceId : 290, ilId : 25, ilce : "HINIS" }, { ilceId : 291, ilId : 25, ilce : "HORASAN" }, { ilceId : 292, ilId : 25, ilce : "OLTU" }, { ilceId : 293, ilId : 25, ilce : "İSPİR" }, { ilceId : 294, ilId : 25, ilce : "KARAYAZI" }, { ilceId : 295, ilId : 25, ilce : "NARMAN" }, { ilceId : 296, ilId : 25, ilce : "OLUR" },
     { ilceId : 297, ilId : 25, ilce : "PASİNLER" }, { ilceId : 298, ilId : 25, ilce : "ŞENKAYA" }, { ilceId : 299, ilId : 25, ilce : "TEKMAN" }, { ilceId : 300, ilId : 25, ilce : "TORTUM" }, { ilceId : 301, ilId : 25, ilce : "KARAÇOBAN" }, { ilceId : 302, ilId : 25, ilce : "UZUNDERE" }, { ilceId : 303, ilId : 25, ilce : "PAZARYOLU" }, { ilceId : 304, ilId : 25, ilce : "ILICA" }, { ilceId : 305, ilId : 25, ilce : "KÖPRÜKÖY" }, { ilceId : 306, ilId : 24, ilce : "ÇAYIRLI" }, { ilceId : 307, ilId : 24, ilce : "ERZİNCAN MERKEZ" }, { ilceId : 308, ilId : 24, ilce : "İLİÇ" }, { ilceId : 309, ilId : 24, ilce : "KEMAH" }, { ilceId : 310, ilId : 24, ilce : "KEMALİYE" }, { ilceId : 311, ilId : 24, ilce : "REFAHİYE" }, { ilceId : 312, ilId : 24, ilce : "TERCAN" }, { ilceId : 313, ilId : 24, ilce : "OTLUKBELİ" }, { ilceId : 314, ilId : 26, ilce : "ESKİŞEHİR MERKEZ" },
     { ilceId : 315, ilId : 26, ilce : "ÇİFTELER" }, { ilceId : 316, ilId : 26, ilce : "MAHMUDİYE" }, { ilceId : 317, ilId : 26, ilce : "MİHALIÇLIK" }, { ilceId : 318, ilId : 26, ilce : "SARICAKAYA" }, { ilceId : 319, ilId : 26, ilce : "SEYİTGAZİ" }, { ilceId : 320, ilId : 26, ilce : "SİVRİHİSAR" }, { ilceId : 321, ilId : 26, ilce : "ALPU" }, { ilceId : 322, ilId : 26, ilce : "BEYLİKOVA" }, { ilceId : 323, ilId : 26, ilce : "İNÖNÜ" }, { ilceId : 324, ilId : 26, ilce : "GÜNYÜZÜ" }, { ilceId : 325, ilId : 26, ilce : "HAN" }, { ilceId : 326, ilId : 26, ilce : "MİHALGAZİ" }, { ilceId : 327, ilId : 27, ilce : "ARABAN" }, { ilceId : 328, ilId : 27, ilce : "İSLAHİYE" }, { ilceId : 329, ilId : 27, ilce : "NİZİP" }, { ilceId : 330, ilId : 27, ilce : "OĞUZELİ" }, { ilceId : 331, ilId : 27, ilce : "YAVUZELİ" }, { ilceId : 332, ilId : 27, ilce : "ŞAHİNBEY" }, 
     { ilceId : 333, ilId : 27, ilce : "ŞEHİT KAMİL" }, { ilceId : 334, ilId : 27, ilce : "KARKAMIŞ" }, { ilceId : 335, ilId : 27, ilce : "NURDAĞI" }, { ilceId : 336, ilId : 29, ilce : "GÜMÜŞHANE MERKEZ" }, { ilceId : 337, ilId : 29, ilce : "KELKİT" }, { ilceId : 338, ilId : 29, ilce : "ŞİRAN" }, { ilceId : 339, ilId : 29, ilce : "TORUL" }, { ilceId : 340, ilId : 29, ilce : "KÖSE" }, { ilceId : 341, ilId : 29, ilce : "KÜRTÜN" }, { ilceId : 342, ilId : 28, ilce : "ALUCRA" }, { ilceId : 343, ilId : 28, ilce : "BULANCAK" }, { ilceId : 344, ilId : 28, ilce : "DERELİ" }, { ilceId : 345, ilId : 28, ilce : "ESPİYE" }, { ilceId : 346, ilId : 28, ilce : "EYNESİL" }, { ilceId : 347, ilId : 28, ilce : "GİRESUN MERKEZ" }, { ilceId : 348, ilId : 28, ilce : "GÖRELE" }, { ilceId : 349, ilId : 28, ilce : "KEŞAP" }, { ilceId : 350, ilId : 28, ilce : "ŞEBİNKARAHİSAR" }, 
     { ilceId : 351, ilId : 28, ilce : "TİREBOLU" }, { ilceId : 352, ilId : 28, ilce : "PİPAZİZ" }, { ilceId : 353, ilId : 28, ilce : "YAĞLIDERE" }, { ilceId : 354, ilId : 28, ilce : "ÇAMOLUK" }, { ilceId : 355, ilId : 28, ilce : "ÇANAKÇI" }, { ilceId : 356, ilId : 28, ilce : "DOĞANKENT" }, { ilceId : 357, ilId : 28, ilce : "GÜCE" }, { ilceId : 358, ilId : 30, ilce : "HAKKARİ MERKEZ" }, { ilceId : 359, ilId : 30, ilce : "ÇUKURCA" }, { ilceId : 360, ilId : 30, ilce : "ŞEMDİNLİ" }, { ilceId : 361, ilId : 30, ilce : "YÜKSEKOVA" }, { ilceId : 362, ilId : 31, ilce : "ALTINÖZÜ" }, { ilceId : 363, ilId : 31, ilce : "DÖRTYOL" }, { ilceId : 364, ilId : 31, ilce : "HATAY MERKEZ" }, { ilceId : 365, ilId : 31, ilce : "HASSA" }, { ilceId : 366, ilId : 31, ilce : "İSKENDERUN" }, { ilceId : 367, ilId : 31, ilce : "KIRIKHAN" }, { ilceId : 368, ilId : 31, ilce : "REYHANLI" }, 
     { ilceId : 369, ilId : 31, ilce : "SAMANDAĞ" }, { ilceId : 370, ilId : 31, ilce : "YAYLADAĞ" }, { ilceId : 371, ilId : 31, ilce : "ERZİN" }, { ilceId : 372, ilId : 31, ilce : "BELEN" }, { ilceId : 373, ilId : 31, ilce : "KUMLU" }, { ilceId : 374, ilId : 32, ilce : "ISPARTA MERKEZ" }, { ilceId : 375, ilId : 32, ilce : "ATABEY" }, { ilceId : 376, ilId : 32, ilce : "KEÇİBORLU" }, { ilceId : 377, ilId : 32, ilce : "EĞİRDİR" }, { ilceId : 378, ilId : 32, ilce : "GELENDOST" }, { ilceId : 379, ilId : 32, ilce : "SİNİRKENT" }, { ilceId : 380, ilId : 32, ilce : "ULUBORLU" }, { ilceId : 381, ilId : 32, ilce : "YALVAÇ" }, { ilceId : 382, ilId : 32, ilce : "AKSU" }, { ilceId : 383, ilId : 32, ilce : "GÖNEN" }, { ilceId : 384, ilId : 32, ilce : "YENİŞAR BADEMLİ" }, { ilceId : 385, ilId : 76, ilce : "IĞDIR MERKEZ" }, { ilceId : 386, ilId : 76, ilce : "ARALIK" }, 
     { ilceId : 387, ilId : 76, ilce : "TUZLUCA" }, { ilceId : 388, ilId : 76, ilce : "KARAKOYUNLU" }, { ilceId : 389, ilId : 46, ilce : "AFŞİN" }, { ilceId : 390, ilId : 46, ilce : "ANDIRIN" }, { ilceId : 391, ilId : 46, ilce : "ELBİSTAN" }, { ilceId : 392, ilId : 46, ilce : "GÖKSUN" }, { ilceId : 393, ilId : 46, ilce : "KAHRAMANMARAŞ MERKEZ" }, { ilceId : 394, ilId : 46, ilce : "PAZARCIK" }, { ilceId : 395, ilId : 46, ilce : "TÜRKOĞLU" }, { ilceId : 396, ilId : 46, ilce : "ÇAĞLAYANCERİT" }, { ilceId : 397, ilId : 46, ilce : "EKİNÖZÜ" }, { ilceId : 398, ilId : 46, ilce : "NURHAK" }, { ilceId : 399, ilId : 78, ilce : "EFLANİ" }, { ilceId : 400, ilId : 78, ilce : "ESKİPAZAR" }, { ilceId : 401, ilId : 78, ilce : "KARABÜK MERKEZ" }, { ilceId : 402, ilId : 78, ilce : "OVACIK" }, { ilceId : 403, ilId : 78, ilce : "SAFRANBOLU" }, { ilceId : 404, ilId : 78, ilce : "YENİCE" }, 
     { ilceId : 405, ilId : 70, ilce : "ERMENEK" }, { ilceId : 406, ilId : 70, ilce : "KARAMAN MERKEZ" }, { ilceId : 407, ilId : 70, ilce : "AYRANCI" }, { ilceId : 408, ilId : 70, ilce : "KAZIMKARABEKİR" }, { ilceId : 409, ilId : 70, ilce : "BAŞYAYLA" }, { ilceId : 410, ilId : 70, ilce : "SARIVELİLER" }, { ilceId : 411, ilId : 36, ilce : "KARS MERKEZ" }, { ilceId : 412, ilId : 36, ilce : "ARPAÇAY" }, { ilceId : 413, ilId : 36, ilce : "DİGOR" }, { ilceId : 414, ilId : 36, ilce : "KAĞIZMAN" }, { ilceId : 415, ilId : 36, ilce : "SARIKAMIŞ" }, { ilceId : 416, ilId : 36, ilce : "SELİM" }, { ilceId : 417, ilId : 36, ilce : "SUSUZ" }, { ilceId : 418, ilId : 36, ilce : "AKYAKA" }, { ilceId : 419, ilId : 37, ilce : "ABANA" }, { ilceId : 420, ilId : 37, ilce : "KASTAMONU MERKEZ" }, { ilceId : 421, ilId : 37, ilce : "ARAÇ" }, { ilceId : 422, ilId : 37, ilce : "AZDAVAY" }, 
     { ilceId : 423, ilId : 37, ilce : "BOZKURT" }, { ilceId : 424, ilId : 37, ilce : "CİDE" }, { ilceId : 425, ilId : 37, ilce : "ÇATALZEYTİN" },{ ilceId : 426, ilId : 37, ilce : "DADAY" }, { ilceId : 427, ilId : 37, ilce : "DEVREKANİ" }, { ilceId : 428, ilId : 37, ilce : "İNEBOLU" }, { ilceId : 429, ilId : 37, ilce : "KÜRE" }, { ilceId : 430, ilId : 37, ilce : "TAŞKÖPRÜ" }, { ilceId : 431, ilId : 37, ilce : "TOSYA" }, { ilceId : 432, ilId : 37, ilce : "İHSANGAZİ" }, { ilceId : 433, ilId : 37, ilce : "PINARBAŞI" }, { ilceId : 434, ilId : 37, ilce : "ŞENPAZAR" }, { ilceId : 435, ilId : 37, ilce : "AĞLI" }, { ilceId : 436, ilId : 37, ilce : "DOĞANYURT" }, { ilceId : 437, ilId : 37, ilce : "HANÖNÜ" }, { ilceId : 438, ilId : 37, ilce : "SEYDİLER" }, { ilceId : 439, ilId : 38, ilce : "BÜNYAN" }, { ilceId : 440, ilId : 38, ilce : "DEVELİ" }, { ilceId : 441, ilId : 38, ilce : "FELAHİYE" },
     { ilceId : 442, ilId : 38, ilce : "İNCESU" }, { ilceId : 443, ilId : 38, ilce : "PINARBAŞI" }, { ilceId : 444, ilId : 38, ilce : "SARIOĞLAN" }, { ilceId : 445, ilId : 38, ilce : "SARIZ" }, { ilceId : 446, ilId : 38, ilce : "TOMARZA" }, { ilceId : 447, ilId : 38, ilce : "YAHYALI" }, { ilceId : 448, ilId : 38, ilce : "YEŞİLHİSAR" }, { ilceId : 449, ilId : 38, ilce : "AKKIŞLA" }, { ilceId : 450, ilId : 38, ilce : "TALAS" }, { ilceId : 451, ilId : 38, ilce : "KOCASİNAN" }, { ilceId : 452, ilId : 38, ilce : "MELİKGAZİ" }, { ilceId : 453, ilId : 38, ilce : "HACILAR" }, { ilceId : 454, ilId : 38, ilce : "ÖZVATAN" }, { ilceId : 455, ilId : 71, ilce : "DERİCE" }, { ilceId : 456, ilId : 71, ilce : "KESKİN" }, { ilceId : 457, ilId : 71, ilce : "KIRIKKALE MERKEZ" }, { ilceId : 458, ilId : 71, ilce : "SALAK YURT" }, { ilceId : 459, ilId : 71, ilce : "BAHŞİLİ" }, { ilceId : 460, ilId : 71, ilce : "BALIŞEYH" },
     { ilceId : 461, ilId : 71, ilce : "ÇELEBİ" }, { ilceId : 462, ilId : 71, ilce : "KARAKEÇİLİ" }, { ilceId : 463, ilId : 71, ilce : "YAHŞİHAN" }, { ilceId : 464, ilId : 39, ilce : "KIRKKLARELİ MERKEZ" }, { ilceId : 465, ilId : 39, ilce : "BABAESKİ" }, { ilceId : 466, ilId : 39, ilce : "DEMİRKÖY" }, { ilceId : 467, ilId : 39, ilce : "KOFÇAY" }, { ilceId : 468, ilId : 39, ilce : "LÜLEBURGAZ" }, { ilceId : 469, ilId : 39, ilce : "VİZE" }, { ilceId : 470, ilId : 40, ilce : "KIRŞEHİR MERKEZ" }, { ilceId : 471, ilId : 40, ilce : "ÇİÇEKDAĞI" }, { ilceId : 472, ilId : 40, ilce : "KAMAN" }, { ilceId : 473, ilId : 40, ilce : "MUCUR" }, { ilceId : 474, ilId : 40, ilce : "AKPINAR" }, { ilceId : 475, ilId : 40, ilce : "AKÇAKENT" }, { ilceId : 476, ilId : 40, ilce : "BOZTEPE" }, { ilceId : 477, ilId : 41, ilce : "KOCAELİ MERKEZ" }, { ilceId : 478, ilId : 41, ilce : "GEBZE" }, { ilceId : 479, ilId : 41, ilce : "GÖLCÜK" },
     { ilceId : 480, ilId : 41, ilce : "KANDIRA" }, { ilceId : 481, ilId : 41, ilce : "KARAMÜRSEL" }, { ilceId : 482, ilId : 41, ilce : "KÖRFEZ" }, { ilceId : 483, ilId : 41, ilce : "DERİNCE" }, { ilceId : 484, ilId : 42, ilce : "KONYA MERKEZ" }, { ilceId : 485, ilId : 42, ilce : "AKŞEHİR" }, { ilceId : 486, ilId : 42, ilce : "BEYŞEHİR" }, { ilceId : 487, ilId : 42, ilce : "BOZKIR" }, { ilceId : 488, ilId : 42, ilce : "CİHANBEYLİ" }, { ilceId : 489, ilId : 42, ilce : "ÇUMRA" }, { ilceId : 490, ilId : 42, ilce : "DOĞANHİSAR" }, { ilceId : 491, ilId : 42, ilce : "EREĞLİ" }, { ilceId : 492, ilId : 42, ilce : "HADİM" }, { ilceId : 493, ilId : 42, ilce : "ILGIN" }, { ilceId : 494, ilId : 42, ilce : "KADINHANI" }, { ilceId : 495, ilId : 42, ilce : "KARAPINAR" }, { ilceId : 496, ilId : 42, ilce : "KULU" }, { ilceId : 497, ilId : 42, ilce : "SARAYÖNÜ" }, { ilceId : 498, ilId : 42, ilce : "SEYDİŞEHİR" }, { ilceId : 499, ilId : 42, ilce : "YUNAK" },
     { ilceId : 500, ilId : 42, ilce : "AKÖREN" }, { ilceId : 501, ilId : 42, ilce : "ALTINEKİN" }, { ilceId : 502, ilId : 42, ilce : "DEREBUCAK" }, { ilceId : 503, ilId : 42, ilce : "HÜYÜK" }, { ilceId : 504, ilId : 42, ilce : "KARATAY" }, { ilceId : 505, ilId : 42, ilce : "MERAM" }, { ilceId : 506, ilId : 42, ilce : "SELÇUKLU" }, { ilceId : 507, ilId : 42, ilce : "TAŞKENT" }, { ilceId : 508, ilId : 42, ilce : "AHIRLI" }, { ilceId : 509, ilId : 42, ilce : "ÇELTİK" }, { ilceId : 510, ilId : 42, ilce : "DERBENT" }, { ilceId : 511, ilId : 42, ilce : "EMİRGAZİ" }, { ilceId : 512, ilId : 42, ilce : "GÜNEYSINIR" }, { ilceId : 513, ilId : 42, ilce : "HALKAPINAR" }, { ilceId : 514, ilId : 42, ilce : "TUZLUKÇU" }, { ilceId : 515, ilId : 42, ilce : "YALIHÜYÜK" }, { ilceId : 516, ilId : 43, ilce : "KÜTAHYA  MERKEZ" }, { ilceId : 517, ilId : 43, ilce : "ALTINTAŞ" }, { ilceId : 518, ilId : 43, ilce : "DOMANİÇ" }, { ilceId : 519, ilId : 43, ilce : "EMET" },
     { ilceId : 520, ilId : 43, ilce : "GEDİZ" }, { ilceId : 521, ilId : 43, ilce : "SİMAV" }, { ilceId : 522, ilId : 43, ilce : "TAVŞANLI" }, { ilceId : 523, ilId : 43, ilce : "ASLANAPA" }, { ilceId : 524, ilId : 43, ilce : "DUMLUPINAR" }, { ilceId : 525, ilId : 43, ilce : "HİSARCIK" }, { ilceId : 526, ilId : 43, ilce : "ŞAPHANE" }, { ilceId : 527, ilId : 43, ilce : "ÇAVDARHİSAR" }, { ilceId : 528, ilId : 43, ilce : "PAZARLAR" }, { ilceId : 529, ilId : 79, ilce : "KİLİS MERKEZ" }, { ilceId : 530, ilId : 79, ilce : "ELBEYLİ" }, { ilceId : 531, ilId : 79, ilce : "MUSABEYLİ" }, { ilceId : 532, ilId : 79, ilce : "POLATELİ" }, { ilceId : 533, ilId : 44, ilce : "MALATYA MERKEZ" }, { ilceId : 534, ilId : 44, ilce : "AKÇADAĞ" }, { ilceId : 535, ilId : 44, ilce : "ARAPGİR" }, { ilceId : 536, ilId : 44, ilce : "ARGUVAN" }, { ilceId : 537, ilId : 44, ilce : "DARENDE" }, { ilceId : 538, ilId : 44, ilce : "DOĞANŞEHİR" }, { ilceId : 539, ilId : 44, ilce : "HEKİMHAN" },
     { ilceId : 540, ilId : 44, ilce : "PÜTÜRGE" }, { ilceId : 541, ilId : 44, ilce : "YEŞİLYURT" }, { ilceId : 542, ilId : 44, ilce : "BATTALGAZİ" }, { ilceId : 543, ilId : 44, ilce : "DOĞANYOL" }, { ilceId : 544, ilId : 44, ilce : "KALE" }, { ilceId : 545, ilId : 44, ilce : "KULUNCAK" }, { ilceId : 546, ilId : 44, ilce : "YAZIHAN" }, { ilceId : 547, ilId : 45, ilce : "AKHİSAR" }, { ilceId : 548, ilId : 45, ilce : "ALAŞEHİR" }, { ilceId : 549, ilId : 45, ilce : "DEMİRCİ" }, { ilceId : 550, ilId : 45, ilce : "GÖRDES" }, { ilceId : 551, ilId : 45, ilce : "KIRKAĞAÇ" }, { ilceId : 552, ilId : 45, ilce : "KULA" }, { ilceId : 553, ilId : 45, ilce : "MANİSA MERKEZ" }, { ilceId : 554, ilId : 45, ilce : "SALİHLİ" }, { ilceId : 555, ilId : 45, ilce : "SARIGÖL" }, { ilceId : 556, ilId : 45, ilce : "SARUHANLI" }, { ilceId : 557, ilId : 45, ilce : "SELENDİ" }, { ilceId : 558, ilId : 45, ilce : "SOMA" }, { ilceId : 559, ilId : 45, ilce : "TURGUTLU" }, { ilceId : 560, ilId : 45, ilce : "AHMETLİ" },
     { ilceId : 561, ilId : 45, ilce : "GÖLMARMARA" }, { ilceId : 562, ilId : 45, ilce : "KÖPRÜBAŞI" }, { ilceId : 563, ilId : 47, ilce : "DERİK" }, { ilceId : 564, ilId : 47, ilce : "KIZILTEPE" }, { ilceId : 565, ilId : 47, ilce : "MARDİN MERKEZ" }, { ilceId : 566, ilId : 47, ilce : "MAZIDAĞI" }, { ilceId : 567, ilId : 47, ilce : "MİDYAT" }, { ilceId : 568, ilId : 47, ilce : "NUSAYBİN" }, { ilceId : 569, ilId : 47, ilce : "ÖMERLİ" }, { ilceId : 570, ilId : 47, ilce : "SAVUR" }, { ilceId : 571, ilId : 47, ilce : "YEŞİLLİ" }, { ilceId : 572, ilId : 33, ilce : "MERSİN MERKEZ" }, { ilceId : 573, ilId : 33, ilce : "ANAMUR" }, { ilceId : 574, ilId : 33, ilce : "ERDEMLİ" }, { ilceId : 575, ilId : 33, ilce : "GÜLNAR" }, { ilceId : 576, ilId : 33, ilce : "MUT" }, { ilceId : 577, ilId : 33, ilce : "SİLİFKE" }, { ilceId : 578, ilId : 33, ilce : "TARSUS" }, { ilceId : 579, ilId : 33, ilce : "AYDINCIK" }, { ilceId : 580, ilId : 33, ilce : "BOZYAZI" }, { ilceId : 581, ilId : 33, ilce : "ÇAMLIYAYLA" },
     { ilceId : 582, ilId : 48, ilce : "BODRUM" }, { ilceId : 583, ilId : 48, ilce : "DATÇA" }, { ilceId : 584, ilId : 48, ilce : "FETHİYE" }, { ilceId : 585, ilId : 48, ilce : "KÖYCEĞİZ" }, { ilceId : 586, ilId : 48, ilce : "MARMARİS" }, { ilceId : 587, ilId : 48, ilce : "MİLAS" }, { ilceId : 588, ilId : 48, ilce : "MUĞLA MERKEZ" }, { ilceId : 589, ilId : 48, ilce : "ULA" }, { ilceId : 590, ilId : 48, ilce : "YATAĞAN" }, { ilceId : 591, ilId : 48, ilce : "DALAMAN" }, { ilceId : 592, ilId : 48, ilce : "KAVAKLI DERE" }, { ilceId : 593, ilId : 48, ilce : "ORTACA" }, { ilceId : 594, ilId : 49, ilce : "BULANIK" }, { ilceId : 595, ilId : 49, ilce : "MALAZGİRT" }, { ilceId : 596, ilId : 49, ilce : "MUŞ MERKEZ" }, { ilceId : 597, ilId : 49, ilce : "VARTO" }, { ilceId : 598, ilId : 49, ilce : "HASKÖY" }, { ilceId : 599, ilId : 49, ilce : "KORKUT" }, { ilceId : 600, ilId : 50, ilce : "NEVŞEHİR MERKEZ" }, { ilceId : 601, ilId : 50, ilce : "AVANOS" }, { ilceId : 602, ilId : 50, ilce : "DERİNKUYU" },
     { ilceId : 603, ilId : 50, ilce : "GÜLŞEHİR" }, { ilceId : 604, ilId : 50, ilce : "HACIBEKTAŞ" }, { ilceId : 605, ilId : 50, ilce : "KOZAKLI" }, { ilceId : 606, ilId : 50, ilce : "ÜRGÜP" }, { ilceId : 607, ilId : 50, ilce : "ACIGÖL" }, { ilceId : 608, ilId : 51, ilce : "NİĞDE MERKEZ" }, { ilceId : 609, ilId : 51, ilce : "BOR" }, { ilceId : 610, ilId : 51, ilce : "ÇAMARDI" }, { ilceId : 611, ilId : 51, ilce : "ULUKIŞLA" }, { ilceId : 612, ilId : 51, ilce : "ALTUNHİSAR" }, { ilceId : 613, ilId : 51, ilce : "ÇİFTLİK" }, { ilceId : 614, ilId : 52, ilce : "AKKUŞ" }, { ilceId : 615, ilId : 52, ilce : "AYBASTI" }, { ilceId : 616, ilId : 52, ilce : "FATSA" }, { ilceId : 617, ilId : 52, ilce : "GÖLKÖY" }, { ilceId : 618, ilId : 52, ilce : "KORGAN" }, { ilceId : 619, ilId : 52, ilce : "KUMRU" }, { ilceId : 620, ilId : 52, ilce : "MESUDİYE" }, { ilceId : 621, ilId : 52, ilce : "ORDU MERKEZ" }, { ilceId : 622, ilId : 52, ilce : "PERŞEMBE" }, { ilceId : 623, ilId : 52, ilce : "ULUBEY" }, 
     { ilceId : 624, ilId : 52, ilce : "ÜNYE" }, { ilceId : 625, ilId : 52, ilce : "GÜLYALI" }, { ilceId : 626, ilId : 52, ilce : "GÜRGENTEPE" }, { ilceId : 627, ilId : 52, ilce : "ÇAMAŞ" }, { ilceId : 628, ilId : 52, ilce : "ÇATALPINAR" }, { ilceId : 629, ilId : 52, ilce : "ÇAYBAŞI" }, { ilceId : 630, ilId : 52, ilce : "İKİZCE" }, { ilceId : 631, ilId : 52, ilce : "KABADÜZ" }, { ilceId : 632, ilId : 52, ilce : "KABATAŞ" }, { ilceId : 633, ilId : 80, ilce : "BAHÇE" }, { ilceId : 634, ilId : 80, ilce : "KADİRLİ" }, { ilceId : 635, ilId : 80, ilce : "OSMANİYE MERKEZ" }, { ilceId : 636, ilId : 80, ilce : "DÜZİÇİ" }, { ilceId : 637, ilId : 80, ilce : "HASANBEYLİ" }, { ilceId : 638, ilId : 80, ilce : "SUMBAŞ" }, { ilceId : 639, ilId : 80, ilce : "TOPRAKKALE" }, { ilceId : 640, ilId : 53, ilce : "RİZE MERKEZ" }, { ilceId : 641, ilId : 53, ilce : "ARDEŞEN" }, { ilceId : 642, ilId : 53, ilce : "ÇAMLIHEMŞİN" }, { ilceId : 643, ilId : 53, ilce : "ÇAYELİ" }, { ilceId : 644, ilId : 53, ilce : "FINDIKLI" },
     { ilceId : 645, ilId : 53, ilce : "İKİZDERE" }, { ilceId : 646, ilId : 53, ilce : "KALKANDERE" }, { ilceId : 647, ilId : 53, ilce : "PAZAR" }, { ilceId : 648, ilId : 53, ilce : "GÜNEYSU" }, { ilceId : 649, ilId : 53, ilce : "DEREPAZARI" }, { ilceId : 650, ilId : 53, ilce : "HEMŞİN" }, { ilceId : 651, ilId : 53, ilce : "İYİDERE" }, { ilceId : 652, ilId : 54, ilce : "AKYAZI" }, { ilceId : 653, ilId : 54, ilce : "GEYVE" }, { ilceId : 654, ilId : 54, ilce : "HENDEK" }, { ilceId : 655, ilId : 54, ilce : "KARASU" }, { ilceId : 656, ilId : 54, ilce : "KAYNARCA" }, { ilceId : 657, ilId : 54, ilce : "SAKARYA MERKEZ" }, { ilceId : 658, ilId : 54, ilce : "PAMUKOVA" }, { ilceId : 659, ilId : 54, ilce : "TARAKLI" }, { ilceId : 660, ilId : 54, ilce : "FERİZLİ" }, { ilceId : 661, ilId : 54, ilce : "KARAPÜRÇEK" }, { ilceId : 662, ilId : 54, ilce : "SÖĞÜTLÜ" }, { ilceId : 663, ilId : 55, ilce : "ALAÇAM" }, { ilceId : 664, ilId : 55, ilce : "BAFRA" }, { ilceId : 665, ilId : 55, ilce : "ÇARŞAMBA" }, { ilceId : 666, ilId : 55, ilce : "HAVZA" },
     { ilceId : 667, ilId : 55, ilce : "KAVAK" }, { ilceId : 668, ilId : 55, ilce : "LADİK" }, { ilceId : 669, ilId : 55, ilce : "SAMSUN MERKEZ" }, { ilceId : 670, ilId : 55, ilce : "TERME" }, { ilceId : 671, ilId : 55, ilce : "VEZİRKÖPRÜ" }, { ilceId : 672, ilId : 55, ilce : "ASARCIK" }, { ilceId : 673, ilId : 55, ilce : "ONDOKUZMAYIS" }, { ilceId : 674, ilId : 55, ilce : "SALIPAZARI" }, { ilceId : 675, ilId : 55, ilce : "TEKKEKÖY" }, { ilceId : 676, ilId : 55, ilce : "AYVACIK" }, { ilceId : 677, ilId : 55, ilce : "YAKAKENT" }, { ilceId : 678, ilId : 57, ilce : "AYANCIK" }, { ilceId : 679, ilId : 57, ilce : "BOYABAT" }, { ilceId : 680, ilId : 57, ilce : "SİNOP MERKEZ" }, { ilceId : 681, ilId : 57, ilce : "DURAĞAN" }, { ilceId : 682, ilId : 57, ilce : "ERGELEK" }, { ilceId : 683, ilId : 57, ilce : "GERZE" }, { ilceId : 684, ilId : 57, ilce : "TÜRKELİ" }, { ilceId : 685, ilId : 57, ilce : "DİKMEN" }, { ilceId : 686, ilId : 57, ilce : "SARAYDÜZÜ" }, { ilceId : 687, ilId : 58, ilce : "DİVRİĞİ" }, { ilceId : 688, ilId : 58, ilce : "GEMEREK" },
     { ilceId : 689, ilId : 58, ilce : "GÜRÜN" }, { ilceId : 690, ilId : 58, ilce : "HAFİK" }, { ilceId : 691, ilId : 58, ilce : "İMRANLI" }, { ilceId : 692, ilId : 58, ilce : "KANGAL" }, { ilceId : 693, ilId : 58, ilce : "KOYUL HİSAR" }, { ilceId : 694, ilId : 58, ilce : "SİVAS MERKEZ" }, { ilceId : 695, ilId : 58, ilce : "SU ŞEHRİ" }, { ilceId : 696, ilId : 58, ilce : "ŞARKIŞLA" }, { ilceId : 697, ilId : 58, ilce : "YILDIZELİ" }, { ilceId : 698, ilId : 58, ilce : "ZARA" }, { ilceId : 699, ilId : 58, ilce : "AKINCILAR" }, { ilceId : 700, ilId : 58, ilce : "ALTINYAYLA" }, { ilceId : 701, ilId : 58, ilce : "DOĞANŞAR" }, { ilceId : 702, ilId : 58, ilce : "GÜLOVA" }, { ilceId : 703, ilId : 58, ilce : "ULAŞ" }, { ilceId : 704, ilId : 56, ilce : "BAYKAN" }, { ilceId : 705, ilId : 56, ilce : "ERUH" }, { ilceId : 706, ilId : 56, ilce : "KURTALAN" }, { ilceId : 707, ilId : 56, ilce : "PERVARİ" }, { ilceId : 708, ilId : 56, ilce : "SİİRT MERKEZ" }, { ilceId : 709, ilId : 56, ilce : "ŞİRVARİ" }, { ilceId : 710, ilId : 56, ilce : "AYDINLAR" }, 
     { ilceId : 711, ilId : 59, ilce : "TEKİRDAĞ MERKEZ" }, { ilceId : 712, ilId : 59, ilce : "ÇERKEZKÖY" }, { ilceId : 713, ilId : 59, ilce : "ÇORLU" }, { ilceId : 714, ilId : 59, ilce : "HAYRABOLU" }, { ilceId : 715, ilId : 59, ilce : "MALKARA" }, { ilceId : 716, ilId : 59, ilce : "MURATLI" }, { ilceId : 717, ilId : 59, ilce : "SARAY" }, { ilceId : 718, ilId : 59, ilce : "ŞARKÖY" }, { ilceId : 719, ilId : 59, ilce : "MARAMARAEREĞLİSİ" }, { ilceId : 720, ilId : 60, ilce : "ALMUS" }, { ilceId : 721, ilId : 60, ilce : "ARTOVA" }, { ilceId : 722, ilId : 60, ilce : "TOKAT MERKEZ" }, { ilceId : 723, ilId : 60, ilce : "ERBAA" }, { ilceId : 724, ilId : 60, ilce : "NİKSAR" }, { ilceId : 725, ilId : 60, ilce : "REŞADİYE" }, { ilceId : 726, ilId : 60, ilce : "TURHAL" }, { ilceId : 727, ilId : 60, ilce : "ZİLE" }, { ilceId : 728, ilId : 60, ilce : "PAZAR" }, { ilceId : 729, ilId : 60, ilce : "YEŞİLYURT" }, { ilceId : 730, ilId : 60, ilce : "BAŞÇİFTLİK" }, { ilceId : 731, ilId : 60, ilce : "SULUSARAY" }, { ilceId : 732, ilId : 61, ilce : "TRABZON MERKEZ" }, 
     { ilceId : 733, ilId : 61, ilce : "AKÇAABAT" }, { ilceId : 734, ilId : 61, ilce : "ARAKLI" }, { ilceId : 735, ilId : 61, ilce : "ARŞİN" }, { ilceId : 736, ilId : 61, ilce : "ÇAYKARA" }, { ilceId : 737, ilId : 61, ilce : "MAÇKA" }, { ilceId : 738, ilId : 61, ilce : "OF" }, { ilceId : 739, ilId : 61, ilce : "SÜRMENE" }, { ilceId : 740, ilId : 61, ilce : "TONYA" }, { ilceId : 741, ilId : 61, ilce : "VAKFIKEBİR" }, { ilceId : 742, ilId : 61, ilce : "YOMRA" }, { ilceId : 743, ilId : 61, ilce : "BEŞİKDÜZÜ" }, { ilceId : 744, ilId : 61, ilce : "ŞALPAZARI" }, { ilceId : 745, ilId : 61, ilce : "ÇARŞIBAŞI" }, { ilceId : 746, ilId : 61, ilce : "DERNEKPAZARI" }, { ilceId : 747, ilId : 61, ilce : "DÜZKÖY" }, { ilceId : 748, ilId : 61, ilce : "HAYRAT" }, { ilceId : 749, ilId : 61, ilce : "KÖPRÜBAŞI" }, { ilceId : 750, ilId : 62, ilce : "TUNCELİ MERKEZ" }, { ilceId : 751, ilId : 62, ilce : "ÇEMİŞGEZEK" }, { ilceId : 752, ilId : 62, ilce : "HOZAT" }, { ilceId : 753, ilId : 62, ilce : "MAZGİRT" }, { ilceId : 754, ilId : 62, ilce : "NAZİMİYE" }, 
     { ilceId : 755, ilId : 62, ilce : "OVACIK" }, { ilceId : 756, ilId : 62, ilce : "PERTEK" }, { ilceId : 757, ilId : 62, ilce : "PÜLÜMÜR" }, { ilceId : 758, ilId : 64, ilce : "BANAZ" }, { ilceId : 759, ilId : 64, ilce : "EŞME" }, { ilceId : 760, ilId : 64, ilce : "KARAHALLI" }, { ilceId : 761, ilId : 64, ilce : "SİVASLI" }, { ilceId : 762, ilId : 64, ilce : "ULUBEY" }, { ilceId : 763, ilId : 64, ilce : "UŞAK MERKEZ" }, { ilceId : 764, ilId : 65, ilce : "BAŞKALE" }, { ilceId : 765, ilId : 65, ilce : "VAN MERKEZ" }, { ilceId : 766, ilId : 65, ilce : "EDREMİT" }, { ilceId : 767, ilId : 65, ilce : "ÇATAK" }, { ilceId : 768, ilId : 65, ilce : "ERCİŞ" }, { ilceId : 769, ilId : 65, ilce : "GEVAŞ" }, { ilceId : 770, ilId : 65, ilce : "GÜRPINAR" }, { ilceId : 771, ilId : 65, ilce : "MURADİYE" }, { ilceId : 772, ilId : 65, ilce : "ÖZALP" }, { ilceId : 773, ilId : 65, ilce : "BAHÇESARAY" }, { ilceId : 774, ilId : 65, ilce : "ÇALDIRAN" }, { ilceId : 775, ilId : 65, ilce : "SARAY" }, { ilceId : 776, ilId : 77, ilce : "YALOVA MERKEZ" }, 
     { ilceId : 777, ilId : 77, ilce : "ALTINOVA" }, { ilceId : 778, ilId : 77, ilce : "ARMUTLU" }, { ilceId : 779, ilId : 77, ilce : "ÇINARCIK" }, { ilceId : 780, ilId : 77, ilce : "ÇİFTLİKKÖY" }, { ilceId : 781, ilId : 77, ilce : "TERMAL" }, { ilceId : 782, ilId : 66, ilce : "AKDAĞMADENİ" }, { ilceId : 783, ilId : 66, ilce : "BOĞAZLIYAN" }, { ilceId : 784, ilId : 66, ilce : "YOZGAT MERKEZ" }, { ilceId : 785, ilId : 66, ilce : "ÇAYIRALAN" }, { ilceId : 786, ilId : 66, ilce : "ÇEKEREK" }, { ilceId : 787, ilId : 66, ilce : "SARIKAYA" }, { ilceId : 788, ilId : 66, ilce : "SORGUN" }, { ilceId : 789, ilId : 66, ilce : "ŞEFAATLI" }, { ilceId : 790, ilId : 66, ilce : "YERKÖY" }, { ilceId : 791, ilId : 66, ilce : "KADIŞEHRİ" }, { ilceId : 792, ilId : 66, ilce : "SARAYKENT" }, { ilceId : 793, ilId : 66, ilce : "YENİFAKILI" }, { ilceId : 794, ilId : 67, ilce : "ÇAYCUMA" }, { ilceId : 795, ilId : 67, ilce : "DEVREK" }, { ilceId : 796, ilId : 67, ilce : "ZONGULDAK MERKEZ" }, { ilceId : 797, ilId : 67, ilce : "EREĞLİ" }, { ilceId : 798, ilId : 67, ilce : "ALAPLI" }, 
     { ilceId : 799, ilId : 67, ilce : "GÖKÇEBEY" }, { ilceId : 800, ilId : 17, ilce : "ÇANAKKALE MERKEZ" }, { ilceId : 801, ilId : 17, ilce : "AYVACIK" }, { ilceId : 802, ilId : 17, ilce : "BAYRAMİÇ" }, { ilceId : 803, ilId : 17, ilce : "BİGA" }, { ilceId : 804, ilId : 17, ilce : "BOZCAADA" }, { ilceId : 805, ilId : 17, ilce : "ÇAN" }, { ilceId : 806, ilId : 17, ilce : "ECEABAT" }, { ilceId : 807, ilId : 17, ilce : "EZİNE" }, { ilceId : 808, ilId : 17, ilce : "LAPSEKİ" }, { ilceId : 809, ilId : 17, ilce : "YENİCE" }, { ilceId : 810, ilId : 18, ilce : "ÇANKIRI MERKEZ" }, { ilceId : 811, ilId : 18, ilce : "ÇERKEŞ" }, { ilceId : 812, ilId : 18, ilce : "ELDİVAN" }, { ilceId : 813, ilId : 18, ilce : "ILGAZ" }, { ilceId : 814, ilId : 18, ilce : "KURŞUNLU" }, { ilceId : 815, ilId : 18, ilce : "ORTA" }, { ilceId : 816, ilId : 18, ilce : "ŞABANÖZÜ" }, { ilceId : 817, ilId : 18, ilce : "YAPRAKLI" }, { ilceId : 818, ilId : 18, ilce : "ATKARACALAR" }, { ilceId : 819, ilId : 18, ilce : "KIZILIRMAK" }, { ilceId : 820, ilId : 18, ilce : "BAYRAMÖREN" }, { ilceId : 821, ilId : 18, ilce : "KORGUN" }, 
     { ilceId : 822, ilId : 19, ilce : "ALACA" }, { ilceId : 823, ilId : 19, ilce : "BAYAT" }, { ilceId : 824, ilId : 19, ilce : "ÇORUM MERKEZ" }, { ilceId : 825, ilId : 19, ilce : "İKSİPLİ" }, { ilceId : 826, ilId : 19, ilce : "KARGI" }, { ilceId : 827, ilId : 19, ilce : "MECİTÖZÜ" }, { ilceId : 828, ilId : 19, ilce : "ORTAKÖY" }, { ilceId : 829, ilId : 19, ilce : "OSMANCIK" }, { ilceId : 830, ilId : 19, ilce : "SUNGURLU" }, { ilceId : 831, ilId : 19, ilce : "DODURGA" }, { ilceId : 832, ilId : 19, ilce : "LAÇİN" }, { ilceId : 833, ilId : 19, ilce : "OĞUZLAR" }, { ilceId : 834, ilId : 34, ilce : "ADALAR" }, { ilceId : 835, ilId : 34, ilce : "BAKIRKÖY" }, { ilceId : 836, ilId : 34, ilce : "BEŞİKTAŞ" }, { ilceId : 837, ilId : 34, ilce : "BEYKOZ" }, { ilceId : 838, ilId : 34, ilce : "BEYOĞLU" }, { ilceId : 839, ilId : 34, ilce : "ÇATALCA" }, { ilceId : 840, ilId : 34, ilce : "EMİNÖNÜ" }, { ilceId : 841, ilId : 34, ilce : "EYÜP" }, { ilceId : 842, ilId : 34, ilce : "FATİH" }, { ilceId : 843, ilId : 34, ilce : "GAZİOSMANPAŞA" }, { ilceId : 844, ilId : 34, ilce : "KADIKÖY" }, 
     { ilceId : 845, ilId : 34, ilce : "KARTAL" }, { ilceId : 846, ilId : 34, ilce : "SARIYER" }, { ilceId : 847, ilId : 34, ilce : "SİLİVRİ" }, { ilceId : 848, ilId : 34, ilce : "ŞİLE" }, { ilceId : 849, ilId : 34, ilce : "ŞİŞLİ" }, { ilceId : 850, ilId : 34, ilce : "ÜSKÜDAR" }, { ilceId : 851, ilId : 34, ilce : "ZEYTİNBURNU" }, { ilceId : 852, ilId : 34, ilce : "BÜYÜKÇEKMECE" }, { ilceId : 853, ilId : 34, ilce : "KAĞITHANE" }, { ilceId : 854, ilId : 34, ilce : "KÜÇÜKÇEKMECE" }, { ilceId : 855, ilId : 34, ilce : "PENDİK" }, { ilceId : 856, ilId : 34, ilce : "ÜMRANİYE" }, { ilceId : 857, ilId : 34, ilce : "BAYRAMPAŞA" }, { ilceId : 858, ilId : 34, ilce : "AVCILAR" }, { ilceId : 859, ilId : 34, ilce : "BAĞCILAR" }, { ilceId : 860, ilId : 34, ilce : "BAHÇELİEVLER" }, { ilceId : 861, ilId : 34, ilce : "GÜNGÖREN" }, { ilceId : 862, ilId : 34, ilce : "MALTEPE" }, { ilceId : 863, ilId : 34, ilce : "SULTANBEYLİ" }, { ilceId : 864, ilId : 34, ilce : "TUZLA" }, { ilceId : 865, ilId : 34, ilce : "ESENLER" }, { ilceId : 866, ilId : 35, ilce : "ALİAĞA" }, { ilceId : 867, ilId : 35, ilce : "BAYINDIR" }, 
     { ilceId : 868, ilId : 35, ilce : "BERGAMA" }, { ilceId : 869, ilId : 35, ilce : "BORNOVA" }, { ilceId : 870, ilId : 35, ilce : "ÇEŞME" }, { ilceId : 871, ilId : 35, ilce : "DİKİLİ" }, { ilceId : 872, ilId : 35, ilce : "FOÇA" }, { ilceId : 873, ilId : 35, ilce : "KARABURUN" }, { ilceId : 874, ilId : 35, ilce : "KARŞIYAKA" }, { ilceId : 875, ilId : 35, ilce : "KEMALPAŞA" }, { ilceId : 876, ilId : 35, ilce : "KINIK" }, { ilceId : 877, ilId : 35, ilce : "KİRAZ" }, { ilceId : 878, ilId : 35, ilce : "MENEMEN" }, { ilceId : 879, ilId : 35, ilce : "ÖDEMİŞ" }, { ilceId : 880, ilId : 35, ilce : "SEFERİHİSAR" }, { ilceId : 881, ilId : 35, ilce : "SELÇUK" }, { ilceId : 882, ilId : 35, ilce : "TİRE" }, { ilceId : 883, ilId : 35, ilce : "TORBALI" }, { ilceId : 884, ilId : 35, ilce : "URLA" }, { ilceId : 885, ilId : 35, ilce : "BEYDAĞ" }, { ilceId : 886, ilId : 35, ilce : "BUCA" }, { ilceId : 887, ilId : 35, ilce : "KONAK" }, { ilceId : 888, ilId : 35, ilce : "MENDERES" }, { ilceId : 889, ilId : 35, ilce : "BALÇOVA" }, { ilceId : 890, ilId : 35, ilce : "ÇİGLİ" }, { ilceId : 891, ilId : 35, ilce : "GAZİEMİR" },
     { ilceId : 892, ilId : 35, ilce : "NARLIDERE" }, { ilceId : 893, ilId : 35, ilce : "GÜZELBAHÇE" }, { ilceId : 894, ilId : 63, ilce : "ŞANLIURFA MERKEZ" }, { ilceId : 895, ilId : 63, ilce : "AKÇAKALE" }, { ilceId : 896, ilId : 63, ilce : "BİRECİK" }, { ilceId : 897, ilId : 63, ilce : "BOZOVA" }, { ilceId : 898, ilId : 63, ilce : "CEYLANPINAR" }, { ilceId : 899, ilId : 63, ilce : "HALFETİ" }, { ilceId : 900, ilId : 63, ilce : "HİLVAN" }, { ilceId : 901, ilId : 63, ilce : "SİVEREK" }, { ilceId : 902, ilId : 63, ilce : "SURUÇ" }, { ilceId : 903, ilId : 63, ilce : "VİRANŞEHİR" }, { ilceId : 904, ilId : 63, ilce : "HARRAN" }, { ilceId : 905, ilId : 73, ilce : "BEYTÜŞŞEBAP" }, { ilceId : 906, ilId : 73, ilce : "ŞIRNAK MERKEZ" }, { ilceId : 907, ilId : 73, ilce : "CİZRE" }, { ilceId : 908, ilId : 73, ilce : "İDİL" }, { ilceId : 909, ilId : 73, ilce : "SİLOPİ" }, { ilceId : 910, ilId : 73, ilce : "ULUDERE" }, { ilceId : 911, ilId : 73, ilce : "GÜÇLÜKONAK" }      
]

module.exports={iller,ilceler}