import React, { useState, useEffect, useContext } from 'react';
import ConcubineList from './concubineList';
import { GET_COMPANIES_CONCUBINE } from '../../../../GraphQL/Queries/concubine/concubine';
import { AuthContext } from '../../../../context/authContext';
import { ConcubinesContext } from '../../../../context/concubinesContext';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import './companyConcubinePage.scss';

const CompanyConcubinePage = () => {
    const navigate = useNavigate();
    const { signOut} = useContext(AuthContext);
    const {getConcubines, concubineState: { concubines } } = useContext(ConcubinesContext);
    const [selects, setSelects] = useState([]);
    
    const [getCompanyConcubine, { loading: getCompanyConcubineLoading }] = useLazyQuery(GET_COMPANIES_CONCUBINE , {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            getConcubines(data.companyConcubines);            
        },
        onError({ graphQLErrors }) {
            const err = graphQLErrors[0].extensions;
            console.log(err)
            if (err.status && err.status == 401) {
                signOut()
                navigate('/portal-giris')
            }            
        }
    });
  
    useEffect(() => {
        !concubines.length > 0 && getCompanyConcubine()        
        return () => {
        }
    }, [])

    return (
        <div className='concubine-container'>
            <div className='list-alt concubineTable'>
                <ConcubineList select={{ selects, setSelects }}  getCompanyConcubineLoading={getCompanyConcubineLoading}/>
            </div>

        </div>
    );
}

export default CompanyConcubinePage;