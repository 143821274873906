import React from 'react';
 
  const NotFound =()=>{
    
        return(
          <div className='h-100 my-auto mx-auto'>
                <h1>Sayfa Bulunamadı !</h1>
          </div>
        );
    
} 

export default NotFound;