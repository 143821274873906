import { gql } from "@apollo/client"



export const SEND_INVOICE = gql`
mutation SendInvoice($data: sendInvoiceInput!) {
  sendInvoice(data: $data) {
    status
  }
}
`


export const CREATE_WABA_USER = gql`
mutation Mutation($data: createWabaUserInput!) {
  createWabaUser(data: $data) {
     status
  }
}
`
