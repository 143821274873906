import React, { useState, Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SEND_INVOICE, CREATE_WABA_USER } from '../../../../GraphQL/Mutations/whatsapp/whatsapp';
import { CHANGE_INVOICE } from '../../../../GraphQL/Mutations/concubine/concubine'
import { AiFillFileAdd, AiFillFile } from "react-icons/ai";
import { AuthContext } from '../../../../context/authContext';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';



const WhatsAppPage = () => {
    const navigate = useNavigate();
    const { signOut } = useContext(AuthContext);
    const [messageList, setMessageList] = useState();
    const [userList, setUserList] = useState();
    const [invoiceList, setInvoiceList] = useState();

    const [sendInvoice, { loading, error }] = useMutation(SEND_INVOICE, {
        onCompleted(data) {
            console.log(data)
        },
        onError({ graphQLErrors }) {
            const err = graphQLErrors[0].extensions;
            console.log(err)
            if (err.status && err.status == 401) {
                signOut()
                navigate('/portal-giris')
            }
        }
    });

    const [createWabaUser, { loadingCreateUser, errorcreateUser }] = useMutation(CREATE_WABA_USER, {
        onCompleted(data) {
            console.log(data)
        },
        onError(graphQLErrors) {
            const err = graphQLErrors[0].extensions;
            console.log(err)
            if (err.status && err.status == 401) {
                signOut()
                navigate('/portal-giris')
            }
        }
    });

    const [changeInvoice, { loadingChangeInvoice, errorChangeInvoice }] = useMutation(CHANGE_INVOICE, {
        onCompleted(data) {
            console.log(data)
        },
        onError(graphQLErrors) {
            const err = graphQLErrors[0].extensions;
            console.log(err)
            if (err.status && err.status == 401) {
                signOut()
                navigate('/portal-giris')
            }
        }
    });


    const readFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setMessageList(e.target.files[0])
        }
        else {
            setMessageList()
        }
    };

    const readInvoiceFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setInvoiceList(e.target.files[0])
        }
        else {
            setInvoiceList()
        }
    };

    const readUserFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setUserList(e.target.files[0])
        }
        else {
            setUserList()
        }
    };

    const onFormSubmit = async e => {
        e.preventDefault();

        if (messageList) {

            // dispatch(setItemsLoading());
            const promise = new Promise(function (resolve, reject) {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(messageList);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: 'buffer' });
                    const wsName = wb.SheetNames[0];
                    const ws = wb.Sheets[wsName];

                    const data = XLSX.utils.sheet_to_json(ws);
                    const veri = data;
                    //const veri=data.slice(0,10);
                    resolve(veri);
                };

                fileReader.onerror = (error) => {
                    //  toastr(error,'HATA');
                }
            });

            async function asyncForEach(array, callback) {
                for (let index = 0; index < array.length; index++) {
                    await callback(array[index], index, array);
                }
            }

            await promise.then(async (invoices) => {
                console.log(invoices)
                await asyncForEach(invoices, async (invoice) => {
                    if (invoice) {
                        const data = await new Promise((resolve, reject) => {
                            try {
                                const result = sendInvoice({ variables: { data: { "to": `90${invoice["Telefon Numarasi"]}`, "company": invoice["Alıcı Adı"], "invoiceDate": invoice["Fatura Tarihi"], "invoiceAmount": invoice["Tutar"], "type": "upload", "fileName": invoice["Fatura Numarası"] } } })
                                resolve(result);
                            } catch (error) {
                                reject(error);
                            }
                        })
                    }
                })
            })
        }
    }

    const onUserFormSubmit = async e => {
        e.preventDefault();

        if (userList) {

            // dispatch(setItemsLoading());
            const promise = new Promise(function (resolve, reject) {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(userList);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: 'buffer' });
                    const wsName = wb.SheetNames[0];
                    const ws = wb.Sheets[wsName];

                    const data = XLSX.utils.sheet_to_json(ws);
                    const veri = data;
                    //const veri=data.slice(0,10);
                    resolve(veri);
                };

                fileReader.onerror = (error) => {
                    //  toastr(error,'HATA');
                }
            });

            async function asyncForEach(array, callback) {
                for (let index = 0; index < array.length; index++) {
                    await callback(array[index], index, array);
                }
            }

            await promise.then(async (users) => {

                await asyncForEach(users, async (user) => {
                    if (user) {
                        const data = await new Promise((resolve, reject) => {
                            try {
                                console.log({ "phoneNumber": `${user["phoneNumber"]}`, "companyAuths": user["vergiNumarasi"] })
                                const result = createWabaUser({ variables: { data: { "phoneNumber": `${user["phoneNumber"]}`, "companyAuths": user["vergiNumarasi"] } } })
                                resolve(result);
                            } catch (error) {
                                reject(error);
                            }
                        })
                    }
                })
            })
        }
    }

    const onInvoiceFormSubmit = async e => {
        e.preventDefault();

        if (invoiceList) {

            // dispatch(setItemsLoading());
            const promise = new Promise(function (resolve, reject) {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(invoiceList);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: 'buffer' });
                    const wsName = wb.SheetNames[0];
                    const ws = wb.Sheets[wsName];

                    const data = XLSX.utils.sheet_to_json(ws);
                    const veri = data;
                    //const veri=data.slice(0,10);
                    resolve(veri);
                };

                fileReader.onerror = (error) => {
                    //  toastr(error,'HATA');
                }
            });

            async function asyncForEach(array, callback) {
                for (let index = 0; index < array.length; index++) {
                    await callback(array[index], index, array);
                }
            }

            await promise.then(async (invoices) => {
                await asyncForEach(invoices, async (invoice) => {
                    if (invoice) {
                        const data = await new Promise((resolve, reject) => {
                            try {
                                const result = changeInvoice({ variables: { data: { "aliciAdi": invoice["Alıcı Adı"], "faturaNumarasi": invoice["Fatura Numarası"], "faturaTarihi": dayjs(invoice["Fatura Tarihi"], 'DD/MM/YYYY').format(), "tutar": invoice["Tutar"].replace(",", '.') } } })
                                resolve(result);
                            } catch (error) {
                                reject(error);
                            }
                        })
                    }
                })
            })
        }
    }


    return (
        <Fragment>
            <form onSubmit={onFormSubmit} id='messageForm'>
                <div className='rows row'>
                    <div md="12">
                        <fieldset>
                            <label htmlFor='atama' className='mb-0 font-weight-bold text-secondary'>Mesaj Dosyası</label>
                            <input type="file" name="file" id="file" className="inputfile" onChange={readFile} />
                            <label htmlFor="file" name="atama" id="atama" type="button" className='btn' style={{ lineHeight: '1.4' }}>
                                {!messageList ? <AiFillFileAdd style={{ height: '1.28em', width: '1.28em', color: 'red' }} /> : <AiFillFile style={{ height: '1.28em', width: '1.28em' }} />}
                                <span>{!messageList ? 'Bir dosya seç...' : messageList.name}</span>
                            </label>
                        </fieldset>
                    </div>
                </div>
                <button type="submit" id="messageForm" className='btn' form='messageForm' style={{ lineHeight: '1.4' }} disabled={!messageList ? 'disabled' : ''}>
                    <span>Oluştur</span>
                </button>
            </form>
            <div>User Saves</div>
            <form onSubmit={onUserFormSubmit} id='userForm'>
                <div className='rows row'>
                    <div md="12">
                        <fieldset>
                            <label htmlFor='userfile' className='mb-0 font-weight-bold text-secondary'>Mesaj Dosyası</label>
                            <input type="file" name="userfile" id="userfile" className="inputfile" onChange={readUserFile} />
                            <label htmlFor="userfile" name="userfile" id="userfile" type="button" className='btn' style={{ lineHeight: '1.4' }}>
                                {!userList ? <AiFillFileAdd style={{ height: '1.28em', width: '1.28em', color: 'red' }} /> : <AiFillFile style={{ height: '1.28em', width: '1.28em' }} />}
                                <span>{!userList ? 'Bir dosya seç...' : userList.name}</span>
                            </label>
                        </fieldset>
                    </div>
                </div>
                <button type="submit" id="userForm" className='btn' form='userForm' style={{ lineHeight: '1.4' }} disabled={!userList ? 'disabled' : ''}>
                    <span>Oluştur</span>
                </button>
            </form>
            <div>Fatura Numaraları Update</div>
            <form onSubmit={onInvoiceFormSubmit} id='invoiceForm'>
                <div className='rows row'>
                    <div md="12">
                        <fieldset>
                            <label htmlFor='invoicefile' className='mb-0 font-weight-bold text-secondary'>Mesaj Dosyası</label>
                            <input type="file" name="invoicefile" id="invoicefile" className="inputfile" onChange={readInvoiceFile} />
                            <label htmlFor="invoicefile" name="invoicefile" id="invoicefile" type="button" className='btn' style={{ lineHeight: '1.4' }}>
                                {!invoiceList ? <AiFillFileAdd style={{ height: '1.28em', width: '1.28em', color: 'red' }} /> : <AiFillFile style={{ height: '1.28em', width: '1.28em' }} />}
                                <span>{!invoiceList ? 'Bir dosya seç...' : invoiceList.name}</span>
                            </label>
                        </fieldset>
                    </div>
                </div>
                <button type="submit" id="invoiceForm" className='btn' form='invoiceForm' style={{ lineHeight: '1.4' }} disabled={!invoiceList ? 'disabled' : ''}>
                    <span>Oluştur</span>
                </button>
            </form>

        </Fragment>
    );
}

export default WhatsAppPage;